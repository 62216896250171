<template>
  <div ref="container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "click-outside",
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    handleClickOutside(event) {
      if (
        this.$refs.container &&
        !this.$refs.container.contains(event.target)
      ) {
        this.$emit("click-outside", event);
      }
    },
  },
};
</script>
