<template>
  <div class="side-menu-element">
    <router-link
      class="menu_element_block flex items-center align-middle rounded-full"
      :to="'/' + path"
    >
      <span
        class="avtive_position"
        :class="{ 'is-active-position-little': isLittle }"
      ></span>
      <icon
        :data="icon"
        width="22"
        height="22"
        class="menu_element_icon"
        original
      />
      <div
        v-if="!isLittle"
        class="menu_element ml-2 font-medium lg:text-sm xl:text-base 2xl:text-lg"
      >
        {{ menu }}
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "side-menu-element",
  props: {
    menu: String,
    icon: String,
    path: String,
    isLittle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";
.svg-fill {
  fill: transparent;
}
.is-active-position-little {
  width: 20% !important;
}
.menu_element_block {
  padding: 12px;
  margin: 0.1rem 0;
  position: relative;
  color: #5c5e64;
  cursor: pointer;
  width: 100%;
  .avtive_position {
    position: absolute;
    left: 0;
    top: 0;
    height: 50px;
    width: 100%;
    z-index: 0;
    visibility: hidden;
  }
  .menu_element_icon::v-deep {
    z-index: 1;
  }
  .menu_element {
    width: 100%;
    z-index: 1;
  }
  &:hover {
    .avtive_position {
      visibility: hidden;
      background: rgba(255, 255, 255, 0.15);
    }
  }
  &.router-link-active {
    color: #000;
    .avtive_position {
      visibility: visible;
      background: #fbdb6c;
      border-radius: 9px;
      //border-right: 3px solid $white;
    }
    .menu_element_icon::v-deep {
      path {
        fill: #000;
      }
    }
  }
}
</style>
