<template>
  <div class="home h-screen overflow-hidden">
    <div class="content-body">
      <div class="header w-full">
        <the-header></the-header>
      </div>
      <div class="main_body w-full flex">
        <div class="nav_sidebar">
          <the-sidebar></the-sidebar>
        </div>
        <section
          class="main_content h-[92vh] w-full py-6 pl-6 pr-10 overflow-y-scroll"
        >
          <router-view class="viewer container m-auto w-full" />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import notification from "../assets/icons/ic_notification.svg";
import ic_logo from "../assets/icons/ic_logo.svg";
import TheSidebar from "@/components/the-sidebar";
import TheHeader from "@/components/the-header";
export default {
  name: "HomeView",
  components: { TheHeader, TheSidebar },
  data() {
    return {
      icons: { notification, ic_logo },
    };
  },
};
</script>

<style scoped>
.svg-fill {
  fill: transparent;
}
.home {
  background: #f6f1e9;
}
.container {
  padding: 0;
}
</style>
