import { createRouter, createWebHistory } from "vue-router";
import pathLoader from "../plugins/loader";
import HomeView from "../views/HomeView.vue";
import store from "@/store";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: pathLoader("login", "index"),
  },
  {
    path: "/ressetPassword",
    name: "ResetPassword",
    component: pathLoader("reset", "index"),
  },
  {
    path: "/",
    name: "Home",
    component: HomeView,
    redirect: "/dashboard",
    children: [
      {
        path: "/updatePassword",
        name: "UpdatePassword",
        component: pathLoader("update-password", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: pathLoader("dashboard", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/transactions",
        name: "Transactions",
        component: pathLoader("transactions", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/filleul",
        name: "Filleul",
        component: pathLoader("filleul", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/newsletter",
        name: "Newsletter",
        component: pathLoader("newsletter", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/statistic",
        name: "Statistic",
        component: pathLoader("statistic", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/program",
        name: "Program",
        component: pathLoader("program", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/partner",
        name: "Partner",
        component: pathLoader("partner", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/participant",
        name: "Participant",
        component: pathLoader("participant", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panelist",
        name: "Panelist",
        component: pathLoader("panelist", "index"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin",
        name: "Admin",
        component: pathLoader("admin", "index"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.token;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ name: "Login" }); // Rediriger vers la page de connexion
    } else {
      if (store.state.user.role === "promoteur") {
        // console.log("rouuu", store.state.user);
        // next({ name: "filleul" });
      }
      next(); // L'utilisateur est connecté, continuez vers la route demandée
    }
  } else {
    next(); // Cette route n'exige pas d'authentification
  }
});

export default router;
