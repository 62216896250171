import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import router from "@/router";

const store = createStore({
  plugins: [createPersistedState()],
  state: {
    token: null,
    user: null,
    selectedPath: null,
  },
  getters: {},
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    },
    setSelectedPath(state, path) {
      state.selectedPath = path;
    },
  },
  actions: {
    logout({ commit }) {
      // Réinitialiser les données d'authentification ou effectuer d'autres opérations nécessaires pour la déconnexion
      commit("setToken", null); // Par exemple, si vous stockez les informations de l'utilisateur dans votre store, réinitialisez-le à null lors de la déconnexion
      commit("setUser", null);
      commit("setSelectedPath", null);
      router.push({ path: "/login" });
    },
    setSelectedPath({ commit }, path) {
      commit("setSelectedPath", path);
    },
  },
  modules: {},
});

export default store;
