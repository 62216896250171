import "./assets/style/css/tailwind.css";
import Toaster from "@incuca/vue3-toaster";
import VCalendar from "v-calendar";
import "v-calendar/style.css";
import VueMask from "@devindex/vue-mask";
export default {
  install(app) {
    app.use(Toaster);
    app.use(VCalendar, {});
    app.use(VueMask);
  },
};
