<template>
  <router-view />
</template>

<script>
import store from "@/store";
import router from "@/router";
export default {
  created() {
    this.checkTokenValidity();
  },
  methods: {
    checkTokenValidity() {
      // Récupérez la date de connexion et le token stockés dans le Local Storage
      const tokenDate = localStorage.getItem("tokenDate");
      const token = store.state.token;
      const currentTime = new Date().getTime();
      const tokenValidityDuration = 48 * 60 * 60 * 1000; // 48 heures en millisecondes

      if (
        tokenDate &&
        token &&
        currentTime - tokenDate > tokenValidityDuration
      ) {
        localStorage.removeItem("tokenDate");
        router.push({ path: "/login" });
      } else {
        console.log(
          "Le token est toujours valide, continuez à utiliser l'application"
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/style/sass/variables";
#app {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input,
button,
select,
textarea {
  outline: none !important;
  &:focus,
  &:active {
    outline: none;
  }
}
</style>
