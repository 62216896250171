<template>
  <div class="the-sidebar">
    <div
      class="begin xl:h-screen 2xl:h-screen 2xl:w-80 xl:w-64 bg-[#FEFBF6] relative"
      :class="{ 'is-little': isOpenMenu }"
    >
      <div class="cursor-pointer absolute -right-4 -top-4">
        <icon :data="icons.ic_reduce" original width="34" height="34"></icon>
      </div>
      <div
        v-if="!isOpenMenu"
        class="side_menu_container p-4 lg:h-[90vh] xl:h-[90vh] 2xl:h-[93vh] overflow-y-scroll"
      >
        <div class="xl:mt-6 2xl:mt-12">
          <div class="ml-4 text-[#5C5E64] font-medium text-xs mb-4">
            INFORMATIONS
          </div>
          <side-menu-element
            v-if="user.role === 'admin'"
            menu="Tableau de bord"
            :icon="icons.ic_dashboard"
            path="dashboard"
          ></side-menu-element>
          <side-menu-element
            v-if="user.role === 'admin'"
            menu="Transactions"
            :icon="icons.ic_estimate"
            path="transactions"
          ></side-menu-element>
          <side-menu-element
            v-if="user.role === 'promoteur'"
            menu="Filleuls"
            :icon="icons.ic_client"
            path="filleul"
          ></side-menu-element>
          <side-menu-element
            v-if="user.role === 'admin'"
            menu="Newsletters"
            :icon="icons.ic_client"
            path="newsletter"
          ></side-menu-element>
        </div>
        <div class="mt-6">
          <div class="ml-4 text-[#5C5E64] font-medium text-xs mb-4">
            MANAGEMENT
          </div>
          <side-menu-element
            menu="Statistiques"
            :icon="icons.ic_statistic"
            path="statistic"
          ></side-menu-element>
          <side-menu-element
            v-if="user.role === 'admin'"
            menu="Programmes"
            :icon="icons.ic_insurer"
            path="program"
            class="hidden"
          ></side-menu-element>
          <side-menu-element
            v-if="user.role === 'admin'"
            menu="Partenaires"
            :icon="icons.ic_insurer"
            path="partner"
          ></side-menu-element>
          <side-menu-element
            v-if="user.role === 'admin'"
            menu="Participants"
            :icon="icons.ic_product"
            path="participant"
            class="hidden"
          ></side-menu-element>
          <side-menu-element
            v-if="user.role === 'admin'"
            menu="Panelistes"
            :icon="icons.ic_product"
            path="panelist"
          ></side-menu-element>
          <side-menu-element
            v-if="user.role === 'admin'"
            menu="Promoteurs"
            :icon="icons.ic_user"
            path="admin"
          ></side-menu-element>
          <side-menu-element
            v-if="user.role === 'promoteur'"
            menu="Mot de passe"
            :icon="icons.ic_user"
            path="updatePassword"
          ></side-menu-element>
        </div>
      </div>
      <div
        v-if="isOpenMenu"
        class="side_menu_container p-4 xl:h-[90vh] 2xl:h-[93vh] overflow-y-scroll"
      >
        <div class="mt-12">
          <div class="ml-4 text-[#5C5E64] font-medium text-xs mb-4">
            INFORMATIONS
          </div>
          <side-menu-element
            menu="Tableau de bord"
            :icon="icons.ic_dashboard"
            path="dashboard"
            :is-little="true"
          ></side-menu-element>
          <side-menu-element
            menu="Transactions"
            :icon="icons.ic_devis"
            path="transactions"
            :is-little="true"
          ></side-menu-element>
          <side-menu-element
            v-if="user.role === 'promoteur'"
            menu="Filleuls"
            :icon="icons.ic_client"
            path="filleul"
          ></side-menu-element>
          <side-menu-element
            menu="Newsletter"
            :icon="icons.ic_client"
            path="newsletter"
            :is-little="true"
          ></side-menu-element>
        </div>
        <div class="mt-6">
          <div class="ml-4 text-[#5C5E64] font-medium text-xs mb-4">
            MANAGEMENT
          </div>
          <side-menu-element
            menu="Statistiques"
            :icon="icons.ic_statistic"
            path="statistic"
            :is-little="true"
          ></side-menu-element>
          <side-menu-element
            menu="Programmes"
            :icon="icons.ic_insurer"
            path="insurer"
            :is-little="true"
            class="hidden"
          ></side-menu-element>
          <side-menu-element
            menu="Partenaires"
            :icon="icons.ic_insurer"
            path="partner"
          ></side-menu-element>
          <side-menu-element
            menu="Participants"
            :icon="icons.ic_product"
            path="participants"
            :is-little="true"
            class="hidden"
          ></side-menu-element>
          <side-menu-element
            menu="Panelistes"
            :icon="icons.ic_product"
            path="panelistes"
            :is-little="true"
          ></side-menu-element>
          <side-menu-element
            menu="Promoteurs"
            :icon="icons.ic_user"
            path="user"
            :is-little="true"
          ></side-menu-element>
          <side-menu-element
            v-if="user.role === 'promoteur'"
            menu="Mot de passe"
            :icon="icons.ic_user"
            path="updatePassword"
            :is-little="true"
          ></side-menu-element>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ic_reduce from "@/assets/icons/ic_reduce-menu.svg";
import SideMenuElement from "@/components/helper/side-menu-element.vue";
import ic_dashboard from "@/assets/icons/ic_dashboard-menu.svg";
import ic_estimate from "@/assets/icons/ic_estimate-menu.svg";
import ic_client from "@/assets/icons/ic_client-menu.svg";
import ic_webhook from "@/assets/icons/ic_webook-menu.svg";
import ic_insurer from "@/assets/icons/ic_insurer-menu.svg";
import ic_product from "@/assets/icons/ic_product-insurance-menu.svg";
import ic_stat from "@/assets/icons/ic_menu-stats.svg";
import ic_user from "@/assets/icons/ic_user-menu.svg";
import ic_statistic from "@/assets/icons/ic_menu-stats.svg";
// import SidebarItem from "./helper/sidebar-item.vue";
import store from "@/store";
export default {
  name: "the-sidebar",
  components: {
    SideMenuElement, //SidebarItem
  },
  data() {
    return {
      icons: {
        ic_reduce,
        ic_client,
        ic_estimate,
        ic_dashboard,
        ic_webhook,
        ic_insurer,
        ic_product,
        ic_stat,
        ic_user,
        ic_statistic,
      },
      user: null,
      isOpenMenu: false,
    };
  },
  created() {
    this.user = store.state.user;
  },
  methode: {},
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";
.svg-fill {
  fill: transparent;
}
.begin {
  box-shadow: 4.549898147583008px 2.274949073791504px 5.68737268447876px 0
    #0000000d;
}
.is-little {
  width: 7.3rem !important;
}
::-webkit-scrollbar {
  width: 0; /* Largeur de la barre de défilement */
}

/* Poignée de la barre de défilement */
::-webkit-scrollbar-thumb {
  background: #888; /* Couleur de la poignée */
}

.begin {
  scrollbar-width: none;
  scrollbar-color: #888 #f0f0f0;
}
</style>
